import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.ShowDashboardCounts()
    this.ShowLastDashboardTab()
  }

  ShowLastDashboardTab() {
    $('a[data-toggle="tab"]').on('show.bs.tab', (event) => {
      const target = event.target.getAttribute('href')
      localStorage.setItem('dashboard', target)
      const tab = document.querySelector(target)
      this.UpdateTaskList(tab)
    })  
    document.querySelector(`ul.nav-tabs>li>a[href='${localStorage.getItem('dashboard')}']`).click()
  }
  
  ShowDashboardCounts() {
    fetch('/home/dashboard.json', {credentials: 'include'})
    .then(response => response.json())
    .then(data => {
      document.getElementById('today').innerHTML = data.dashboard.calls_today
      document.getElementById('pending').innerHTML = data.dashboard.pending
      document.getElementById('outstanding').innerHTML = data.dashboard.outstanding
      document.getElementById('followon').innerHTML = data.dashboard.followon
      document.getElementById('complete').innerHTML = data.dashboard.complete
      document.getElementById('invoice').innerHTML = data.dashboard.invoice
    })
  }

  TasksUpdated(event) {
    const target = event.target.getAttribute('href')
    const tab = document.querySelector(target)
    this.UpdateTaskList(tab)
  }

  UpdateTaskList(tab) {    
    const tasks = tab.getAttribute('data-tasks')
    const heading = tab.getAttribute('data-heading')
    tab.innerHTML = `<h1 class="text-center"><i class="fa fa-spinner fa-pulse fa-spin fa-fw"></i></h1>`
    const url = `/home/tasks/${tasks}?heading=${heading}`
    fetch(url, {credentials: 'include'})
    .then(response => response.text())
    .then(html => {
      tab.innerHTML = html
    })
  }
}