import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'files' ]

  connect() {
    this.filesTarget.addEventListener("change", this.uploadFiles.bind(this))
  }

  uploadFiles(event) {
    event.target.form.submit()
  }
}
