import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'term', 'search', 'output', 'tools', 'about', 'appliance', 'comments', 'postcode', 'addressLookup' ]

  connect() {
    // Grab the CSRF token that rails wants
    this.csrf_token = document.querySelector( 'meta[name="csrf-token"]' ).getAttribute( 'content' )
    // Remember the initial markup
    this.initialOutput = this.outputTarget.innerHTML    
  }

  search() {
    var term = this.termTarget.value
    if(term.length > 3) {
      var height = window.innerHeight - 300
      this.searchTarget.style.overflowY = 'scroll'
      this.searchTarget.style.maxHeight = `${height}px`
      this.renderFragment(this.searchTarget, `calls/search/${term}`)
    } else {
      this.searchTarget.innerHTML = ''
    }
  }

  choose(event) {
    event.preventDefault()
    this.callerID = event.currentTarget.getAttribute('data-caller')
    this.renderFragment(this.outputTarget, `calls/fragment/enquiry/${this.callerID}`)
  }

  reset() {
    $('form', this.element).trigger('checkform.areYouSure');
    // Check to set if any form has a dirty class
    if($('form.dirty').length > 0) {
      $('#dirtyform .continue').attr('href', '/')
      $('#dirtyform').modal('show')
    } else {
      Turbolinks.visit('/')
    }
  }

  enquiry() {
    this.renderFragment(this.outputTarget, 'calls/fragment/enquiry')
    $('form', this.element).areYouSure({silent: true})
  }

  appliance() {
    if(this.aboutTarget.value == 'appliance') {
      this.applianceTarget.classList.remove('hidden')
    } else {
      this.applianceTarget.classList.add('hidden')
    }
    if(this.callerID != null) {
      this.renderFragment(this.commentsTarget, `calls/fragment/comments/${this.callerID}?about=${this.aboutTarget.value}`)
    }
  }

  findAddress(event) {
    event.preventDefault()
    if(this.postcodeTarget.value) {
      this.renderFragment(this.addressLookupTarget, `postcode/${this.postcodeTarget.value}`)
      $(this.addressLookupTarget).modal()
    }
  }

  useAddress(event) {
    var address = event.currentTarget
    $(this.addressLookupTarget).modal('hide')
    this.element.querySelector('#property_address_attributes_address1').value = address.getAttribute('data-address1')
    this.element.querySelector('#property_address_attributes_address2').value = address.getAttribute('data-address2')
    this.element.querySelector('#property_address_attributes_address3').value = address.getAttribute('data-address3')
    this.element.querySelector('#property_address_attributes_town').value = address.getAttribute('data-town')
    this.element.querySelector('#property_address_attributes_county').value = address.getAttribute('data-county')
    this.element.querySelector('#property_address_attributes_postcode').value = address.getAttribute('data-postcode')
  }

  renderFragment(target, path) {
    var url
    if(path.startsWith('http')) {
      url = new URL(path)
    } else {
      url = new URL(path, document.location.origin)
    }
    fetch(url, {credentials: 'include'})
      .then(response => response.text())
      .then(html => {
        target.innerHTML = html
        $('form').areYouSure({silent: true});
        this.setFocus()
      })
  }

  // Set the focus to the first input with an autofocus attribute
  setFocus() {
    var input = this.outputTarget.querySelector('input[autofocus]')
    if(input !== null) input.focus()
  }
  
}
