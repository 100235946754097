import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // Grab the CSRF token that rails wants
    this.csrf_token = document.querySelector( 'meta[name="csrf-token"]' ).getAttribute( 'content' )
    // Remember the initial markup
    this.initialOutput = this.element.innerHTML
    // Render the show page
    if(this.data.has('url')) {
      this.baseURL = this.data.get('url')
      this.renderFragment(this.element, this.baseURL)
    }
  }

  edit(event) {
    this.renderFragment(this.element, this.urlFor('edit'))
  }

  cancel(event) {
    this.renderFragment(this.element, this.baseURL)
  }

  save(event) {
    this.postForm(this.element, this.baseURL)
  }

  postForm(target, path) {
    const formData = new FormData(this.element.querySelector('form'))
    let data = {}
    for(const [key, value] of formData.entries()) {
      data[key] = value
    }
    debugger
    data = Rails.serializeElement(this.element.querySelector('form'))
    fetch(path, {
      method: 'PATCH',
      body: data,
      headers: {
        'Content-Type': 'application/x-form-urlencoded',
        'X-CSRF-Token': this.csrf_token
      },
      credentials: 'same-origin'
    })
    .then(response => response.text())
    .then(html => {
      target.innerHTML = html
    })
  }

  renderFragment(target, path) {
    fetch(path, {credentials: 'same-origin'})
    .then(response => response.text())
    .then(html => {
      target.innerHTML = html
      this.setFocus()
    })
  }

  // Set the focus to the first input with an autofocus attribute
  setFocus() {
    var input = this.element.querySelector('input[autofocus]')
    if(input !== null) input.focus()
  }

  urlFor(action) {
    return `${this.baseURL}/${action}`
  }
}