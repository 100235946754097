// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Helpers from "helperFunctions"

export default class extends Controller {
  static targets = [ 'schedule', 'hour', 'duration', 'submit', 'holidayForm', 'holidayResource', 'holidayDate' ]

  connect() {
    this.element.style.height = `${window.innerHeight - 216}px`
  }

  schedule(event) {
    if(this.data.has('task')) {
      this.showDialog(event)
    } else if(this.data.has('holiday')) {
      event.stopPropagation()
      const clicked = event.currentTarget
      if (clicked.classList.contains('holiday') || clicked.classList.contains('free')) {
        var resource_id = this.data.get('holiday')
        var date = clicked.getAttribute('data-date')
        this.holidayResourceTarget.value = resource_id
        this.holidayDateTarget.value = date
        Rails.fire(this.holidayFormTarget, 'submit')
      } else {
        alert("Day is not free. Please re-schedule tasks")
      }
    }
  }

  task(event) {
    if(this.data.has('task')) {
    } else {
      event.stopPropagation()
      Turbolinks.visit(`/tasks/${event.currentTarget.getAttribute('data-id')}`)
    }
  }

  book(event) {
    var hour = Number(event.currentTarget.getAttribute('data-hour'))
    this.hourTarget.value = hour
    this.showProposed()
    this.submitTarget.classList.remove('disabled')
  }
  
  submit(event) {
    this.element.querySelector('form').submit()
  }

  duration(event) {
    this.showProposed()
  }

  showProposed() {
    var hour = Number(this.hourTarget.value)
    for(let ele of this.element.querySelectorAll('.slot[data-hour]')) {
      var slot = Number(ele.getAttribute('data-hour'))
      if(slot >= hour && slot < hour + Number(this.durationTarget.value)) {
        ele.classList.add('proposed')
      } else {
        ele.classList.remove('proposed')
      }
    }
  }

  showDialog(event) {
    var row = Helpers.findAncestor(event.currentTarget, 'resource-row')
    var resourceID = row.querySelector('th.resource').getAttribute('data-resource')
    var taskID = this.data.get('task')
    var date = event.currentTarget.getAttribute('data-date')
    var duration = this.scheduleTarget.dataset.duration
    var mode = this.scheduleTarget.dataset.mode
    Helpers.renderFragment(this.scheduleTarget, `/tasks/${taskID}/schedule/${resourceID}/${date}/${duration}/${mode}`)
    .then(function(e) {
      $('#scheduleDialog').on('shown.bs.modal', function(e) {
        if($('#scheduleDialog .slots').length > 0) {
          $('#scheduleDialog .slots')[0].scrollTop = 165
        }
      })
      $('#scheduleDialog').modal({
        show: true,
        backdrop: 'static'
      })
    })
  }
}
