module.exports = {

  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  },

  renderFragment(target, path) {
    var url
    if(path.startsWith('http')) {
      url = new URL(path)
    } else {
      url = new URL(path, document.location.origin)
    }
    return fetch(url, {credentials: 'include'})
      .then(response => response.text())
      .then(html => {
        target.innerHTML = html
        this.setFocus(target)
      })
  },

  // Set the focus to the first input with an autofocus attribute
  setFocus(target) {
    var input = target.querySelector('input[autofocus]')
    if(input !== null) input.focus()
  }

}