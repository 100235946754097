import { Controller } from "stimulus"
import Helpers from "helperFunctions"

export default class extends Controller {
  static targets = [ 'term', 'results' ]

  search() {
    var term = this.termTarget.value
    if(term.length > 3) {
      var width = window.innerWidth - 457
      var height = window.innerHeight - 300
      this.resultsTarget.style.width = `${width}px`
      this.resultsTarget.style.overflowY = 'scroll'
      this.resultsTarget.style.maxHeight = `${height}px`
      Helpers.renderFragment(this.resultsTarget, `/search/${term}`)
    } else {
      this.resultsTarget.innerHTML = ''
    }
  }

}