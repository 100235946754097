import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ 'upload', 'form' ]

  connect() {
    this.filesToUpload = 0
    this.dropZone = document.getElementById('dropzone')
    if (this.dropZone) {
      this.element.addEventListener("dragenter", this.showDropzone.bind(this))
      this.dropZone.addEventListener("dragenter", this.showDropzone.bind(this))
      this.element.addEventListener("dragover", this.showDropzone.bind(this))
      this.dropZone.addEventListener("dragover", this.showDropzone.bind(this))
      this.dropZone.addEventListener("dragleave", this.hideDropzone.bind(this))
      this.dropZone.addEventListener("drop", this.handleDrop.bind(this))
    }
  }

  showDropzone(event) {
    event.preventDefault()
    this.dropZone.classList.add('dragover')
  }

  hideDropzone(event) {
    event.preventDefault()
    this.dropZone.classList.remove('dragover')
  }

  handleDrop(event) {
    event.preventDefault()
    this.dropZone.classList.add('progress')
    if (event.dataTransfer.items) {
      this.filesToUpload = event.dataTransfer.files.length
      for(let file of event.dataTransfer.files) {
        this.uploadFile(file)
      }
    }    
  }

  uploadFile(file) {
    const upload = new DirectUpload(file, this.uploadTarget.dataset.directUploadUrl)

    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden")
        hiddenField.setAttribute("value", blob.signed_id)
        hiddenField.name = this.uploadTarget.name
        this.formTarget.appendChild(hiddenField)
      }
      this.fileCompleted()
    })
  }

  fileCompleted() {
    this.filesToUpload--
    if (this.filesToUpload == 0) {
      this.dropZone.classList.remove('progress')
      this.dropZone.classList.remove('dragover')
      this.formTarget.submit()
    }
  }
}
